import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { API_RESOURCE_TRAINING_TARGET_CATEGORY } from 'Consts/apiResources';
import { ADMIN_TRAINING_TARGET_CATEGORIES_MANAGE } from 'Consts/routes';

import { withVariables } from 'Utils/string';

import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layoutAdmin/Spinner';
import ElementEditor from 'Components/layoutAdmin/panel/ElementEditor';

export default class AdminTrainingTargetsEditor extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            create: PropTypes.func.isRequired,
            update: PropTypes.func.isRequired,
            setEnabledStatus: PropTypes.func.isRequired,
        }).isRequired,
        data: PropTypes.object,
    };
    static defaultProps = {
        data: null,
    };

    state = {
        formState: this.props.data || {}, //eslint-disable-line react/destructuring-assignment
    };

    componentDidUpdate = prevProps => {
        const { data } = this.props;

        if (data && JSON.stringify(data) !== JSON.stringify(prevProps.data)) {
            this.setState(prevState => ({
                formState: {
                    ...prevState.formState,
                    ...data,
                },
            }));
        }
    }

    onSubmit = formState => {
        const { data } = this.props;

        return data && data.id
            ? this.onUpdate(formState)
            : this.onCreate(formState);
    }

    onCreate = formState => {
        const { actions, history } = this.props;

        return actions.create({
            ...formState,
        })
            .then(response => {
                history.push(
                    withVariables(
                        ADMIN_TRAINING_TARGET_CATEGORIES_MANAGE.path,
                        { id: response.payload[API_RESOURCE_TRAINING_TARGET_CATEGORY].id }
                    )
                );
            });
    }
    onUpdate = formState => {
        const { data, actions } = this.props;

        return actions.update({
            ...formState,
            id: data.id,
        });
    }

    render() {
        const { data, location, history, actions } = this.props;
        const { formState } = this.state;

        if (!formState) {
            return (<Spinner />);
        }

        return (
            <StyledComponent
                className="admin-training-target-categories-editor"
                styles={require('./styles')}
            >
                <ElementEditor
                    location={location}
                    history={history}
                    details={[{
                        visible: Boolean(data),
                        label: 'Identyfikator',
                        value: formState.id,
                    }, {
                        visible: Boolean(data),
                        label: 'Slug',
                        value: formState.slug,
                    }]}
                    controls={[{
                        visible: Boolean(data && data.id && data.enabled),
                        title: 'Wyłącz kategorię celu treningowy',
                        subtitle: 'Kategoria celu treningowego nie będzie dostępna dla użytkownika',
                        buttonProps: {
                            onClick: () => actions.setEnabledStatus({ id: data && data.id, enabled: false }),
                            children: 'Wyłącz',
                        },
                    }, {
                        visible: Boolean(data && data.id && !data.enabled),
                        title: 'Włącz kategorię celu treningowy',
                        subtitle: 'Kategoria celu treningowego będzie dostępna dla użytkownika',
                        buttonProps: {
                            onClick: () => actions.setEnabledStatus({ id: data && data.id, enabled: true }),
                            children: 'Włącz',
                        },
                    }]}
                    forms={[{
                        title: 'Dane',
                        name: 'editor',
                        submitAction: this.onSubmit,
                        data: formState,
                        onStateChange: formState => {
                            this.setState({
                                formState,
                            });
                        },
                        elements: [{
                            type: 'input',
                            name: 'name',
                            label: 'Nazwa',
                            required: true,
                        }],
                    }]}
                />
            </StyledComponent>
        );
    }
}